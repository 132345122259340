import plLocale from '@fullcalendar/core/locales/pl';
import '@fullcalendar/core/main.css';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import '@fullcalendar/timegrid/main.css';
import { DateInput } from 'common/dateInput.component';
import UserContext from 'common/userContext';
import { renderEventContent } from 'dashboard/calendar-events';
import { DashboardContext } from 'dashboard/Dashboard.component';
import { CategoryFilter } from 'dashboard/day-schedule/CategoryFilter.component';
import { ActiveEmployeeSubstitutionTask } from 'dashboard/day-schedule/tasks/activeEmployeeSubstitutionTask.component';
import { AssistanceStartDateToFillTask } from 'dashboard/day-schedule/tasks/assistanceStartDateToFillTask.component';
import { BasicDataToFillTask } from 'dashboard/day-schedule/tasks/basicDataToFillTask.component';
import { CarHandoverToDoTask } from 'dashboard/day-schedule/tasks/carHandoverToDoTask.component';
import { ClientsAssistantAssignmentTask } from 'dashboard/day-schedule/tasks/clientsAssistantAssignmentTask.component';
import { ClientsWithNotActiveAssistantTask } from 'dashboard/day-schedule/tasks/clientsWithNotActiveAssistantTask.component';
import { ClientsWithoutConsentsTask } from 'dashboard/day-schedule/tasks/clientsWithoutConsentsTask.component';
import { ConcessionApplicationsSettlementCorrectionApplicationsToAcceptTask } from 'dashboard/day-schedule/tasks/concessionApplicationsSettlementCorrectionApplicationsToAcceptTask.component';
import { ConcessionApplicationsToAcceptTask } from 'dashboard/day-schedule/tasks/concessionApplicationsToAcceptTask.component';
import { ConcessionApplicationsToFillTask } from 'dashboard/day-schedule/tasks/concessionApplicationsToFillTask.component';
import { ConcessionApplicationsToSettleTask } from 'dashboard/day-schedule/tasks/concessionApplicationsToSettleTask.component';
import { ConcessionApplicationSupplementsToAcceptTask } from 'dashboard/day-schedule/tasks/concessionApplicationSupplementsToAcceptTask.component';
import { ConsentWithdrawalApplicationsTask } from 'dashboard/day-schedule/tasks/consentWithdrawalApplicationsTask.component';
import { CorrectingInvoiceApplicationsToHandleTask } from 'dashboard/day-schedule/tasks/correctingInvoiceApplicationsToHandleTask.component';
import { CorrectingRegistrationApplicationsTask } from 'dashboard/day-schedule/tasks/correctingRegistrationApplicationsTask.component';
import { DealerMarketingBudgetTargetsApplicationsTask } from 'dashboard/day-schedule/tasks/dealerMarketingBudgetTargetsApplicationsTask.component';
import { DealerMarketingEventsToFillTask } from 'dashboard/day-schedule/tasks/dealerMarketingEventsToFillTask.component';
import { DealerMarketingEventsToReportTask } from 'dashboard/day-schedule/tasks/dealerMarketingEventsToReportTask.component';
import { DelegatedTicketsTask } from 'dashboard/day-schedule/tasks/delegatedTicketsTask.component';
import { ExpectedSeasonalMaintenancesToConfirmTask } from 'dashboard/day-schedule/tasks/expectedSeasonalMaintenancesToConfirmTask.component';
import { ExtraSaleDiscountApplicationsToAcceptFinallyTask } from 'dashboard/day-schedule/tasks/extraSaleDiscountApplicationsToAcceptFinallyTask.component';
import { ExtraSaleDiscountApplicationsToAcceptTask } from 'dashboard/day-schedule/tasks/extraSaleDiscountApplicationsToAcceptTask.component';
import { ExtraSaleDiscountApplicationsToSupplementCarNetListPriceTask } from 'dashboard/day-schedule/tasks/extraSaleDiscountApplicationsToCompleteTask.component';
import { ExtraSaleDiscountApplicationsToCorrectTask } from 'dashboard/day-schedule/tasks/extraSaleDiscountApplicationsToCorrectTask.component';
import { ExtraSaleDiscountApplicationsToGiveAccountingOpinionTask } from 'dashboard/day-schedule/tasks/extraSaleDiscountApplicationsToGiveAccountingOpinionTask.component';
import { ExtraSaleDiscountApplicationsToGiveSalesOpinionTask } from 'dashboard/day-schedule/tasks/extraSaleDiscountApplicationsToGiveSalesOpinionTask.component';
import { ExtraSaleDiscountApplicationsToVerifyNegativeOpinionTask } from 'dashboard/day-schedule/tasks/extraSaleDiscountApplicationsToVerifyNegativeOpinionTask.component';
import { HandoverDateToCorrectTask } from 'dashboard/day-schedule/tasks/handoverDateToCorrectTask.component';
import { HandoverDateToFillTask } from 'dashboard/day-schedule/tasks/handoverDateToFillTask.component';
import { InvoiceApplicationsToAcceptTask } from 'dashboard/day-schedule/tasks/invoiceApplicationsToAcceptTask.component';
import { LeadsAssistantAssignmentTask } from 'dashboard/day-schedule/tasks/leadsAssistantAssignmentTask.component';
import { LeadsTask } from 'dashboard/day-schedule/tasks/leadsTask.component';
import { MissingEurocodesTask } from 'dashboard/day-schedule/tasks/missingEurocodesTask.component';
import { NearLastDateForOrderChangeTask } from 'dashboard/day-schedule/tasks/nearLastDateForOrderChangeTask.component';
import { OrderToSettleTask } from 'dashboard/day-schedule/tasks/orderToSettleTask.component';
import { OrderToWithdrawTask } from 'dashboard/day-schedule/tasks/orderToWithdrawTask.component';
import { OrderWithdrawalApplicationsToAcceptTask } from 'dashboard/day-schedule/tasks/orderWithdrawalApplicationsToAcceptTask.component';
import { OrderWithdrawalApplicationsToAcceptWithoutSettlementTask } from 'dashboard/day-schedule/tasks/orderWithdrawalApplicationsToAcceptWithoutSettlementTask.component';
import { PendingColdCallingsTask } from 'dashboard/day-schedule/tasks/pendingColdCallingsTask.component';
import { PendingConcessionSettlementDocumentationsTask } from 'dashboard/day-schedule/tasks/pendingConcessionSettlementDocumentationsTask.component';
import { PendingOwnPhoneNumberCampaignTask } from 'dashboard/day-schedule/tasks/pendingOwnPhoneNumberCampaignTask.component';
import { RegularOfferToAssignTask } from 'dashboard/day-schedule/tasks/regularOfferToAssignTask.component';
import { RevertCarHandoverApplicationsTask } from 'dashboard/day-schedule/tasks/revertCarHandoverApplicationsTask.component';
import { ScheduledCallsTask } from 'dashboard/day-schedule/tasks/scheduledCallsTask.component';
import { ScheduledCarHandoversTask } from 'dashboard/day-schedule/tasks/scheduledCarHandoversTask.component';
import { ScheduledDemoCarHandoversTask } from 'dashboard/day-schedule/tasks/scheduledDemoCarHandoversTask.component';
import { ScheduledDemoCarReturnsTask } from 'dashboard/day-schedule/tasks/scheduledDemoCarReturnsTask.component';
import { ScheduledMeetingsTask } from 'dashboard/day-schedule/tasks/scheduledMeetingsTask.component';
import { ScheduledOtherContactsTask } from 'dashboard/day-schedule/tasks/scheduledOtherContactsTask.component';
import { ScheduledReturningClientCallsTask } from 'dashboard/day-schedule/tasks/scheduledReturningClientCallsTask.component';
import { ScheduledTestDrivesTask } from 'dashboard/day-schedule/tasks/scheduledTestDrivesTask.component';
import { ServiceRequestsToHandleTask } from 'dashboard/day-schedule/tasks/serviceRequestsToHandleTask.component';
import { TransferApplicationsToAcceptTask } from 'dashboard/day-schedule/tasks/transferApplicationsToAcceptTask.component';
import { UnknownTask } from 'dashboard/day-schedule/tasks/unknownTask.component';
import { UsedCarsLeadToPickUpTask } from 'dashboard/day-schedule/tasks/usedCarsLeadToPickUpTask.component';
import { VehicleCardApplicationForRegistrationCampaignToSubmitTask } from 'dashboard/day-schedule/tasks/vehicleCardApplicationForRegistrationCampaignToSubmitTask.component';
import { useTasks } from 'dashboard/useTasks';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './DaySchedule.less';
import { FleetCarReservationsToAcceptTask } from './tasks/fleetCarReservationsToAcceptTask.component';
export function DaySchedule(props) {
    var tasks = useTasks();
    var _a = useState('ALL_ROLES'), tasksOfRoles = _a[0], setTasksOfRoles = _a[1];
    var dashboardContext = useContext(DashboardContext);
    var calendarRef = useRef();
    useEffect(function () {
        tasks.setRawTasks(props.tasks);
    }, [props.tasks]);
    useEffect(function () {
        tasks.filters.setPhrase(props.filters.phrase);
        tasks.filters.setCategory(props.filters.category);
    }, [props.filters]);
    function selectTasksWithoutBackOffice() {
        setTasksOfRoles('EXCLUDING_BACK_OFFICE');
        props.onExcludeBackOfficeChange(true);
    }
    function selectTasks() {
        setTasksOfRoles('ALL_ROLES');
        props.onExcludeBackOfficeChange(false);
    }
    function applyCategoryFilter(category) {
        tasks.filters.setCategory(category);
    }
    function applyPhraseFilter(phrase) {
        tasks.filters.setPhrase(phrase);
    }
    function applyDateFilter(newDate) {
        if (showCalendar()) {
            calendarRef.current.getApi().gotoDate(moment(newDate).toISOString());
        }
        var newFilter = moment(newDate).isSame(moment(), 'day') ?
            { from: '1900-01-01', to: newDate } :
            { from: newDate, to: newDate };
        tasks.filters.setDate(newFilter);
    }
    function showToday() {
        applyDateFilter(moment().format('YYYY-MM-DD'));
    }
    function isTodayShown() {
        return moment().isSame(moment(tasks.filters.date.to), 'day');
    }
    function previousDay() {
        applyDateFilter(moment(tasks.filters.date.to).subtract(1, 'day'));
    }
    function nextDay() {
        applyDateFilter(moment(tasks.filters.date.to).add(1, 'day'));
    }
    function showCalendar() {
        return UserContext.hasAnyRole('SALES');
    }
    function tasksSwitchButton() {
        if (!UserContext.hasAnyRole('BACK_OFFICE') || !props.onExcludeBackOfficeChange) {
            return;
        }
        if (tasksOfRoles === 'ALL_ROLES') {
            return (React.createElement("button", { className: "btn btn-default", style: { top: 0, marginLeft: '42px' }, onClick: selectTasksWithoutBackOffice, "data-testid": "showMyButton", disabled: props.loading }, "Poka\u017C moje"));
        }
        if (tasksOfRoles === 'EXCLUDING_BACK_OFFICE') {
            return (React.createElement("button", { className: "btn btn-default", style: { top: 0, marginLeft: '42px' }, onClick: selectTasks, "data-testid": "showWithoutBackOfficeButton", disabled: props.loading }, "Widok Administratora"));
        }
    }
    return (React.createElement("article", { className: "day-schedule" },
        React.createElement("section", { className: "day-schedule__header" },
            React.createElement("span", { style: { display: 'flex' } },
                React.createElement("button", { type: "button", className: "btn btn-default", style: { marginRight: '40px' }, onClick: showToday, disabled: isTodayShown(), "data-testid": "todayButton" }, "Dzisiaj"),
                React.createElement("button", { type: "button", className: "btn btn-default fas fa-chevron-left", style: { top: 0 }, onClick: previousDay, disabled: isTodayShown(), "data-testid": "previousDayButton" }),
                React.createElement("div", { style: { margin: '0 4px' } },
                    React.createElement(DateInput, { date: tasks.filters.date.to, setDate: applyDateFilter, "data-testid": "tasksDateFilter", minimumDate: moment().toISOString() })),
                React.createElement("button", { type: "button", className: "btn btn-default fas fa-chevron-right", style: { top: 0 }, onClick: nextDay, "data-testid": "nextDayButton" }),
                React.createElement("span", { style: { marginLeft: '42px', flexGrow: 1 } },
                    React.createElement("input", { className: "form-control", type: "text", style: { width: '100%' }, placeholder: "imi\u0119, nazwisko", "data-testid": "textFilter", defaultValue: tasks.filters.phrase, onChange: function (event) { return applyPhraseFilter(event.currentTarget.value); } })),
                tasksSwitchButton()),
            dashboardContext.previewMode ?
                React.createElement("p", { style: { marginTop: '10px' }, "data-testid": "tasksCount" },
                    "Na dzisiaj ma zaplanowane zadania: ",
                    tasks.todayTasksCount,
                    ", ma te\u017C zadania zaleg\u0142e: ",
                    tasks.overdueTasksCount) :
                React.createElement("p", { style: { marginTop: '10px' }, "data-testid": "tasksCount" },
                    "Na dzisiaj masz zaplanowane zadania: ",
                    tasks.todayTasksCount,
                    ", masz te\u017C zadania zaleg\u0142e: ",
                    tasks.overdueTasksCount)),
        React.createElement("section", { className: "day-schedule__body body" },
            React.createElement("div", { className: "body__tasks tasks", "data-testid": "tasks-list" },
                React.createElement("div", { className: "tasks__filters" },
                    React.createElement(CategoryFilter, { value: tasks.filters.category, onChange: applyCategoryFilter })),
                React.createElement("div", { className: "tasks__list" }, tasks.displayedTasks.map(function (task, index) {
                    var component = tasksComponents[task.type];
                    if (component) {
                        return (React.createElement("div", { className: "event-description-block", key: index, "data-testid": "task", "data-task-id": task.id }, component(task)));
                    }
                    else {
                        return (React.createElement("div", { className: "event-description-block", key: index, "data-testid": "task", "data-task-id": task.id },
                            React.createElement(UnknownTask, { task: task })));
                    }
                }))),
            showCalendar() ?
                React.createElement("div", { className: "body__calendar", "data-testid": "day-calendar" },
                    React.createElement("div", { className: "calendar" },
                        React.createElement(FullCalendar, { allDaySlot: false, contentHeight: 600, ref: calendarRef, slotLabelFormat: function (time) {
                                return moment(time.date).format('H');
                            }, header: false, locale: plLocale, defaultDate: moment().toISOString(), plugins: [timeGridPlugin], defaultView: "timeGridDay", businessHours: {
                                start: '9:00',
                                end: '17:00',
                                daysOfWeek: [1, 2, 3, 4, 5, 6],
                            }, scrollTime: "08:30:00", events: tasks.dayCalendarTasks, eventRender: function (e) { return renderEventContent(e, dashboardContext.previewMode); } }))) : null)));
}
var tasksComponents = {
    CLIENTS_WITHOUT_CONSENTS: function (task) { return (React.createElement(ClientsWithoutConsentsTask, { task: task })); },
    SCHEDULED_MEETINGS: function (task) { return React.createElement(ScheduledMeetingsTask, { task: task }); },
    ACTIVE_EMPLOYEE_SUBSTITUTION: function (task) { return (React.createElement(ActiveEmployeeSubstitutionTask, { task: task })); },
    CLIENTS_ASSISTANT_ASSIGNMENT: function (task) { return (React.createElement(ClientsAssistantAssignmentTask, { task: task })); },
    CLIENTS_WITH_NOT_ACTIVE_ASSISTANT: function (task) { return (React.createElement(ClientsWithNotActiveAssistantTask, { task: task })); },
    CONCESSION_APPLICATIONS_SETTLEMENT_CORRECTION_APPLICATIONS_TO_ACCEPT: function (task) { return (React.createElement(ConcessionApplicationsSettlementCorrectionApplicationsToAcceptTask, { task: task })); },
    CONCESSION_APPLICATIONS_TO_ACCEPT: function (task) { return (React.createElement(ConcessionApplicationsToAcceptTask, { task: task })); },
    CONCESSION_APPLICATIONS_TO_FILL: function (task) { return (React.createElement(ConcessionApplicationsToFillTask, { task: task })); },
    CONCESSION_APPLICATIONS_TO_SETTLE: function (task) { return (React.createElement(ConcessionApplicationsToSettleTask, { task: task })); },
    CONCESSION_APPLICATION_SUPPLEMENTS_TO_ACCEPT: function (task) { return (React.createElement(ConcessionApplicationSupplementsToAcceptTask, { task: task })); },
    CONSENT_WITHDRAWAL_APPLICATIONS: function (task) { return (React.createElement(ConsentWithdrawalApplicationsTask, { task: task })); },
    CORRECTING_REGISTRATION_APPLICATIONS: function (task) { return (React.createElement(CorrectingRegistrationApplicationsTask, { task: task })); },
    DEALER_MARKETING_BUDGET_TARGETS_APPLICATIONS: function (task) { return (React.createElement(DealerMarketingBudgetTargetsApplicationsTask, { task: task })); },
    DEALER_MARKETING_EVENTS_TO_FILL: function (task) { return (React.createElement(DealerMarketingEventsToFillTask, { task: task })); },
    DEALER_MARKETING_EVENTS_TO_REPORT: function (task) { return (React.createElement(DealerMarketingEventsToReportTask, { task: task })); },
    INVOICE_APPLICATIONS_TO_ACCEPT: function (task) { return (React.createElement(InvoiceApplicationsToAcceptTask, { task: task })); },
    CORRECTING_INVOICE_APPLICATIONS_TO_HANDLE: function (task) { return (React.createElement(CorrectingInvoiceApplicationsToHandleTask, { task: task })); },
    TRANSFER_APPLICATIONS_TO_ACCEPT: function (task) { return (React.createElement(TransferApplicationsToAcceptTask, { task: task })); },
    LEADS_ASSISTANT_ASSIGNMENT: function (task) { return (React.createElement(LeadsAssistantAssignmentTask, { task: task })); },
    LEADS: function (task) { return React.createElement(LeadsTask, { task: task }); },
    NEAR_LAST_DATE_FOR_ORDER_CHANGE: function (task) { return (React.createElement(NearLastDateForOrderChangeTask, { task: task })); },
    HANDOVER_DATE_TO_FILL: function (task) { return React.createElement(HandoverDateToFillTask, { task: task }); },
    BASIC_DATA_TO_FILL: function (task) { return React.createElement(BasicDataToFillTask, { task: task }); },
    ASSISTANCE_START_DATE_TO_FILL: function (task) { return (React.createElement(AssistanceStartDateToFillTask, { task: task })); },
    CAR_HANDOVER_TO_DO: function (task) { return React.createElement(CarHandoverToDoTask, { task: task }); },
    HANDOVER_DATE_TO_CORRECT: function (task) { return React.createElement(HandoverDateToCorrectTask, { task: task }); },
    ORDER_TO_SETTLE: function (task) { return React.createElement(OrderToSettleTask, { task: task }); },
    ORDER_TO_WITHDRAW: function (task) { return React.createElement(OrderToWithdrawTask, { task: task }); },
    ORDER_WITHDRAWAL_APPLICATIONS_TO_ACCEPT: function (task) { return (React.createElement(OrderWithdrawalApplicationsToAcceptTask, { task: task })); },
    ORDER_WITHDRAWAL_APPLICATIONS_TO_ACCEPT_WITHOUT_SETTLEMENT: function (task) { return (React.createElement(OrderWithdrawalApplicationsToAcceptWithoutSettlementTask, { task: task })); },
    PENDING_COLD_CALLINGS: function (task) { return React.createElement(PendingColdCallingsTask, { task: task }); },
    PENDING_OWN_PHONE_NUMBER_CAMPAIGN: function (task) { return (React.createElement(PendingOwnPhoneNumberCampaignTask, { task: task })); },
    PENDING_CONCESSION_SETTLEMENT_DOCUMENTATIONS: function (task) { return (React.createElement(PendingConcessionSettlementDocumentationsTask, { task: task })); },
    REVERT_CAR_HANDOVER_APPLICATIONS: function (task) { return (React.createElement(RevertCarHandoverApplicationsTask, { task: task })); },
    SCHEDULED_CALLS: function (task) { return React.createElement(ScheduledCallsTask, { task: task }); },
    SCHEDULED_RETURNING_CLIENT_CALLS: function (task) { return (React.createElement(ScheduledReturningClientCallsTask, { task: task })); },
    SCHEDULED_OTHER_CONTACTS: function (task) { return (React.createElement(ScheduledOtherContactsTask, { task: task })); },
    SCHEDULED_TEST_DRIVES: function (task) { return React.createElement(ScheduledTestDrivesTask, { task: task }); },
    SCHEDULED_DEMO_CAR_HANDOVERS: function (task) { return (React.createElement(ScheduledDemoCarHandoversTask, { task: task })); },
    SCHEDULED_DEMO_CAR_RETURNS: function (task) { return (React.createElement(ScheduledDemoCarReturnsTask, { task: task })); },
    SCHEDULED_CAR_HANDOVERS: function (task) { return (React.createElement(ScheduledCarHandoversTask, { task: task })); },
    VEHICLE_CARD_APPLICATION_FOR_REGISTRATION_CAMPAIGN_TO_SUBMIT: function (task) { return (React.createElement(VehicleCardApplicationForRegistrationCampaignToSubmitTask, { task: task })); },
    MISSING_EUROCODES: function (task) { return React.createElement(MissingEurocodesTask, { task: task }); },
    DELEGATED_TICKETS: function (task) { return React.createElement(DelegatedTicketsTask, { task: task }); },
    EXPECTED_SEASONAL_MAINTENANCES_TO_CONFIRM: function (task) { return (React.createElement(ExpectedSeasonalMaintenancesToConfirmTask, { task: task })); },
    SERVICE_REQUESTS_TO_HANDLE: function (task) { return (React.createElement(ServiceRequestsToHandleTask, { task: task })); },
    USED_CARS_LEAD_TO_PICK_UP: function (task) { return React.createElement(UsedCarsLeadToPickUpTask, { task: task }); },
    EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_ACCEPT: function (task) { return (React.createElement(ExtraSaleDiscountApplicationsToAcceptTask, { task: task })); },
    EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_GIVE_SALES_OPINION: function (task) { return (React.createElement(ExtraSaleDiscountApplicationsToGiveSalesOpinionTask, { task: task })); },
    EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_GIVE_ACCOUNTING_OPINION: function (task) { return (React.createElement(ExtraSaleDiscountApplicationsToGiveAccountingOpinionTask, { task: task })); },
    EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_CORRECT: function (task) { return (React.createElement(ExtraSaleDiscountApplicationsToCorrectTask, { task: task })); },
    EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_SUPPLEMENT_CAR_NET_LIST_PRICE: function (task) { return (React.createElement(ExtraSaleDiscountApplicationsToSupplementCarNetListPriceTask, { task: task })); },
    EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_VERIFY_NEGATIVE_OPINION: function (task) { return (React.createElement(ExtraSaleDiscountApplicationsToVerifyNegativeOpinionTask, { task: task })); },
    EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_ACCEPT_FINALLY: function (task) { return (React.createElement(ExtraSaleDiscountApplicationsToAcceptFinallyTask, { task: task })); },
    REGULAR_OFFER_TO_ASSIGN: function (task) { return (React.createElement(RegularOfferToAssignTask, { task: task })); },
    FLEET_CAR_RESERVATIONS_TO_ACCEPT: function (task) { return (React.createElement(FleetCarReservationsToAcceptTask, { task: task })); }
};
