import { AbstractTask } from 'dashboard/day-schedule/tasks/abstractTask.component';
import { polishTimestamp } from '../../../common/formatters/date';
import { shortPartyDescription } from '../../../common/party';
import { modelName } from '../../../common/models';
export function ExtraSaleDiscountApplicationsToSupplementCarNetListPriceTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Uzupełnij wniosek o rabat DRS',
        description: "Wniosek z dnia " + polishTimestamp(task.submissionTimestamp) + ", " + shortPartyDescription(task.party) + ", " + (task.car ? modelName(task.car.modelId) : '') + ", " + (task.car ? task.car.commonOrderNumber : ''),
        redirectUrl: "#/extra-sale-discounts/submission/supplement-car-net-list-price/" + task.id
    });
}
