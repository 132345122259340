import { AbstractTask } from './abstractTask.component';
import { shortPartyDescription } from '../../../common/party';
import { modelName } from '../../../common/models';
export function BasicDataToFillTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Uzupełnij zamówienie',
        description: task.representativeName + " - " + shortPartyDescription(task.party) + ", " + modelName(task.model),
        redirectUrl: "#/orders/" + task.orderId + "/basic-data"
    });
}
