import React, { useState } from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import { FileLinkButton } from '../../common/components/FileLinkButton.component';
export function ReportByYear(props) {
    var _a = useState(currentYear()), selectedYear = _a[0], setSelectedYear = _a[1];
    function yearsUntilNow() {
        return _.range(props.minYear, currentYear() + 1).map(function (year) { return "" + year; });
    }
    function currentYear() {
        return moment().year();
    }
    return (React.createElement("article", null,
        React.createElement("section", { className: 'row panel panel-default' },
            React.createElement("div", { className: 'panel-heading' },
                React.createElement("h4", { className: 'panel-title' }, props.title)),
            React.createElement("div", { className: 'panel-body form-inline' },
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { className: 'control-label', style: { marginRight: '15px' } }, "Rok"),
                    React.createElement("select", { className: 'form-control', defaultValue: selectedYear, onChange: function (e) { return setSelectedYear(+e.target.value); }, "data-testid": 'year-selector' }, yearsUntilNow().map(function (year) { return (React.createElement("option", { value: year, key: year }, year)); }))))),
        React.createElement("section", { className: 'row' },
            React.createElement("div", { className: 'pull-right' },
                React.createElement(FileLinkButton, { "data-testid": 'download-report-button', fileLocation: props.fileLocation(selectedYear) }, "Generuj")))));
}
