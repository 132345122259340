import { useHttp } from 'http/httpService';
export function usePartWholesaler() {
    var http = useHttp();
    return {
        getParty: function (nip, organizationId) {
            return http
                .get("/api/parts-wholesale-parties-whitelist/get-party/" + nip + "/" + organizationId)
                .then(function (response) { return response.data; });
        },
        updatePeriods: function (nip, organizationId, dataToSend) {
            return http.post("/api/parts-wholesale-parties-whitelist/update-activity-periods?nip=" + nip + "&organizationId=" + organizationId, dataToSend);
        },
    };
}
