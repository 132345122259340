import { AbstractTask } from './abstractTask.component';
import { modelName } from '../../../common/models';
export function HandoverDateToFillTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Zaplanuj wydanie',
        description: task.representativeName + " " + modelName(task.model),
        redirectUrl: "#/orders/" + task.orderId + "/planned-handover"
    });
}
