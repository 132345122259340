import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { TableList } from './TableList.component';
import { PanelGroup, Panel } from 'react-bootstrap';
import moment from 'moment';
export function PaginatedItems(_a) {
    var itemsPerPage = _a.itemsPerPage, items = _a.items, onItemClick = _a.onItemClick, onChangeStatus = _a.onChangeStatus, isGlobalSuspend = _a.isGlobalSuspend, globalSuspendingPartyPending = _a.globalSuspendingPartyPending;
    var _b = useState(null), currentItems = _b[0], setCurrentItems = _b[1];
    var _c = useState(0), pageCount = _c[0], setPageCount = _c[1];
    var _d = useState(0), itemOffset = _d[0], setItemOffset = _d[1];
    var _e = useState(), activePartyPanelKey = _e[0], setActivePartyPanelKey = _e[1];
    useEffect(function () {
        var endOffset = itemOffset + itemsPerPage;
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, items]);
    var pageChangeHandler = function (event) {
        var newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
    };
    return (React.createElement(React.Fragment, null,
        isGlobalSuspend ? (currentItems ? (currentItems.map(function (party, index) {
            return (React.createElement(PanelGroup, { key: index, accordion: true, id: "accordion-global-suspend", "data-testid": "accordion-global-suspend", onSelect: function (partyPanelKey) { setActivePartyPanelKey(partyPanelKey); } },
                React.createElement("div", { className: "accordion-global-suspend-row" },
                    React.createElement("div", { className: "global-suspend-wholesaler" },
                        React.createElement(Panel, { eventKey: party.nip },
                            React.createElement(Panel.Heading, null,
                                React.createElement(Panel.Title, { toggle: true },
                                    React.createElement("div", { className: "table-list" },
                                        React.createElement("div", { "data-testid": "global-suspend-list-item", className: "global-suspend-panel-row " + (party.suspendedGlobally ? 'suspended' : '') },
                                            React.createElement("table", { className: "table wholesalers-table" },
                                                React.createElement("tbody", null,
                                                    React.createElement("tr", null,
                                                        React.createElement("td", { className: "global-suspend-table-nip-id-col", "data-testid": "table-cell-nip-id" }, party.nip),
                                                        React.createElement("td", { className: "global-suspend-table-name-col", "data-testid": "table-cell-name" }, party.name),
                                                        React.createElement("td", { className: "global-suspend-table-status-col", "data-testid": "table-cell-status" }, ''),
                                                        React.createElement("td", { className: "global-suspend-table-activity-from-col", "data-testid": "table-cell-beginning-activity" }, '')))),
                                            React.createElement("span", { className: "global-suspend-collapsed-arrow fas fa-chevron-" + (party.nip !== activePartyPanelKey ? 'down' : 'up') }))))),
                            React.createElement(Panel.Body, { collapsible: true },
                                React.createElement("div", { className: "global-suspend-dealers-list" },
                                    React.createElement("table", { className: "dealers-table" },
                                        React.createElement("tbody", null, party.dealers.map(function (dealer) {
                                            return (React.createElement("tr", { className: "dealer-row " + (dealer.suspended ? 'suspended' : ''), key: dealer.organizationId, "data-testid": "global-suspend-dealer-row" },
                                                React.createElement("td", { className: "global-suspend-table-dealer-name-col", "data-testid": "table-cell-dealer-name" }, dealer.name),
                                                React.createElement("td", { className: "global-suspend-table-dealer-status-col", "data-testid": "table-cell-dealer-status" }, dealer.suspended ? 'Zawieszony' : 'Aktywny'),
                                                React.createElement("td", { className: "global-suspend-table-dealer-activity-from-col", "data-testid": "table-cell-dealer-beginning-activity" }, moment(dealer.startOfActivity).format('YYYY-MM-DD'))));
                                        }))))))),
                    React.createElement("div", { className: "global-suspend-table-suspension-checkbox" }, globalSuspendingPartyPending !== party.nip ?
                        React.createElement("input", { type: "checkbox", id: "suspension", checked: party.suspendedGlobally, onChange: function (event) { return onChangeStatus(event.currentTarget.checked, party.nip); } })
                        :
                            React.createElement("div", { className: "global-suspend-suspension-spinner" },
                                React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", style: { margin: 'auto', background: '0 0', display: 'block', shapeRendering: 'auto' }, width: "30", height: "30", viewBox: "0 0 80 80", preserveAspectRatio: "xMidYMid" },
                                    React.createElement("rect", { x: "48", y: "24", rx: "0", ry: "0", width: "4", height: "12", fill: "#b00020" },
                                        React.createElement("animate", { attributeName: "opacity", values: "1;0", keyTimes: "0;1", dur: "1s", begin: "-0.9166666666666666s", repeatCount: "indefinite" })),
                                    React.createElement("rect", { x: "48", y: "24", rx: "0", ry: "0", width: "4", height: "12", fill: "#b00020", transform: "rotate(30 50 50)" },
                                        React.createElement("animate", { attributeName: "opacity", values: "1;0", keyTimes: "0;1", dur: "1s", begin: "-0.8333333333333334s", repeatCount: "indefinite" })),
                                    React.createElement("rect", { x: "48", y: "24", rx: "0", ry: "0", width: "4", height: "12", fill: "#b00020", transform: "rotate(60 50 50)" },
                                        React.createElement("animate", { attributeName: "opacity", values: "1;0", keyTimes: "0;1", dur: "1s", begin: "-0.75s", repeatCount: "indefinite" })),
                                    React.createElement("rect", { x: "48", y: "24", rx: "0", ry: "0", width: "4", height: "12", fill: "#b00020", transform: "rotate(90 50 50)" },
                                        React.createElement("animate", { attributeName: "opacity", values: "1;0", keyTimes: "0;1", dur: "1s", begin: "-0.6666666666666666s", repeatCount: "indefinite" })),
                                    React.createElement("rect", { x: "48", y: "24", rx: "0", ry: "0", width: "4", height: "12", fill: "#b00020", transform: "rotate(120 50 50)" },
                                        React.createElement("animate", { attributeName: "opacity", values: "1;0", keyTimes: "0;1", dur: "1s", begin: "-0.5833333333333334s", repeatCount: "indefinite" })),
                                    React.createElement("rect", { x: "48", y: "24", rx: "0", ry: "0", width: "4", height: "12", fill: "#b00020", transform: "rotate(150 50 50)" },
                                        React.createElement("animate", { attributeName: "opacity", values: "1;0", keyTimes: "0;1", dur: "1s", begin: "-0.5s", repeatCount: "indefinite" })),
                                    React.createElement("rect", { x: "48", y: "24", rx: "0", ry: "0", width: "4", height: "12", fill: "#b00020", transform: "rotate(180 50 50)" },
                                        React.createElement("animate", { attributeName: "opacity", values: "1;0", keyTimes: "0;1", dur: "1s", begin: "-0.4166666666666667s", repeatCount: "indefinite" })),
                                    React.createElement("rect", { x: "48", y: "24", rx: "0", ry: "0", width: "4", height: "12", fill: "#b00020", transform: "rotate(210 50 50)" },
                                        React.createElement("animate", { attributeName: "opacity", values: "1;0", keyTimes: "0;1", dur: "1s", begin: "-0.3333333333333333s", repeatCount: "indefinite" })),
                                    React.createElement("rect", { x: "48", y: "24", rx: "0", ry: "0", width: "4", height: "12", fill: "#b00020", transform: "rotate(240 50 50)" },
                                        React.createElement("animate", { attributeName: "opacity", values: "1;0", keyTimes: "0;1", dur: "1s", begin: "-0.25s", repeatCount: "indefinite" })),
                                    React.createElement("rect", { x: "48", y: "24", rx: "0", ry: "0", width: "4", height: "12", fill: "#b00020", transform: "rotate(270 50 50)" },
                                        React.createElement("animate", { attributeName: "opacity", values: "1;0", keyTimes: "0;1", dur: "1s", begin: "-0.16666666666666666s", repeatCount: "indefinite" })),
                                    React.createElement("rect", { x: "48", y: "24", rx: "0", ry: "0", width: "4", height: "12", fill: "#b00020", transform: "rotate(300 50 50)" },
                                        React.createElement("animate", { attributeName: "opacity", values: "1;0", keyTimes: "0;1", dur: "1s", begin: "-0.08333333333333333s", repeatCount: "indefinite" })),
                                    React.createElement("rect", { x: "48", y: "24", rx: "0", ry: "0", width: "4", height: "12", fill: "#b00020", transform: "rotate(330 50 50)" },
                                        React.createElement("animate", { attributeName: "opacity", values: "1;0", keyTimes: "0;1", dur: "1s", begin: "0s", repeatCount: "indefinite" }))))))));
        })) : null) : (currentItems && currentItems.map(function (party, index) {
            return (React.createElement(TableList, { nip: party.nip, name: party.name, status: party.suspended, startOfActivity: party.startOfActivity, key: index, onClick: function () { return onItemClick(party.nip); }, onChangeStatus: function (event) {
                    return onChangeStatus(event.currentTarget.checked, party.nip);
                } }));
        })),
        items.length > itemsPerPage ? (React.createElement("div", { className: "pagination", "data-testid": "pagination" },
            React.createElement(ReactPaginate, { breakLabel: "...", nextLabel: "Nast\u0119pna strona", onPageChange: pageChangeHandler, pageRangeDisplayed: 5, pageCount: pageCount, previousLabel: "Poprzednia strona", renderOnZeroPageCount: null, pageLinkClassName: "page-link", previousClassName: "page-item", previousLinkClassName: "page-link-prev", nextClassName: "page-item", nextLinkClassName: "page-link-next", breakClassName: "page-item", breakLinkClassName: "page-link", containerClassName: "pagination", activeClassName: "active", activeLinkClassName: "active-link" }))) : null));
}
