import { useHttp } from 'http/httpService';
export function useOrganizations() {
    var http = useHttp();
    return {
        listDealers: function () {
            return http.get('/api/organizations?dealers')
                .then(function (response) { return response.data; });
        },
        load: function (dealerId) {
            return http.get("/api/organizations/" + dealerId)
                .then(function (response) { return response.data; });
        }
    };
}
