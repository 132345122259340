export function fundingName(funding) {
    if (!funding) {
        return '';
    }
    switch (funding.fundingForm.id) {
        case 'DETAILED_LEASING':
            return funding.fundingForm.name + " - " + funding.leasingProvider.name;
        case 'CFM_FUNDING':
            return funding.fundingForm.name + " - " + funding.cfmProvider.name;
        default:
            return funding.fundingForm.name;
    }
}
