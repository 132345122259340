import { AbstractTask } from './abstractTask.component';
import moment from 'moment';
export function NearLastDateForOrderChangeTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Potwierdź specyfikację',
        description: "Pozosta\u0142o dni: " + daysFromToday(task.lastDateForOrderChange) + " " + task.dealerOrder + " " + (task.orderId ? 'Zamówienie' : 'Stock'),
        redirectUrl: task.orderId ? "#/orders/" + task.orderId + "/summary" : "#/stock/" + task.commonOrderNumber + "/summary"
    });
}
var daysFromToday = function (date) { return moment(date).diff(moment(), 'days'); };
