export function selectedFundingFormDescription(funding) {
    if (funding) {
        switch (funding.fundingForms.selected.id) {
            case 'DETAILED_LEASING':
                return funding.fundingForms.selected.name + " - " + funding.leasingProviders.selected.name;
            case 'CFM_FUNDING':
                return funding.fundingForms.selected.name + " - " + funding.cfmProviders.selected.name;
            default:
                return funding.fundingForms.selected.name;
        }
    }
}
