import { AbstractTask } from './abstractTask.component';
export function VehicleCardApplicationForRegistrationCampaignToSubmitTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Zamów karty akcji rejestracyjnej',
        description: "Do zam\u00F3wienia: " + task.count,
        redirectUrl: '#/vehicle-cards/submission'
    });
}
