import { AbstractTask } from './abstractTask.component';
import { polishTimestamp } from '../../../common/formatters/date';
export function ConcessionApplicationsToAcceptTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: polishTimestamp(task.submissionTimestamp),
        action: 'Zaakceptuj rabat VCP',
        description: task.dealer + " " + task.client,
        redirectUrl: "#/concessions/acceptance/" + task.id
    });
}
