import { useHttp } from 'http/httpService';
export function useCarDefinitions() {
    var http = useHttp();
    return {
        search: function (phrase) {
            return http.get("/api/car-definitions?ofGroup&phrase=" + encodeURIComponent(phrase))
                .then(function (response) { return response.data; });
        },
        load: function (commonOrderNumber) {
            return http.get("/api/car-definitions/" + commonOrderNumber)
                .then(function (response) { return response.data; });
        }
    };
}
