import { AbstractTask } from './abstractTask.component';
export function OrderWithdrawalApplicationsToAcceptTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Wycofaj zamówienie',
        description: task.dealerName + ", " + task.dealerOrder,
        redirectUrl: "#/order-withdrawal/" + task.orderId
    });
}
