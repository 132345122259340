import { AbstractTask } from './abstractTask.component';
export function DelegatedTicketsTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Przejmij zgłoszenie',
        description: task.title,
        redirectUrl: "#/ticket/" + task.id
    });
}
