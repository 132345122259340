import { AbstractTask } from './abstractTask.component';
export function PendingColdCallingsTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Akcja telefoniczna - Przekazanie klientom informacji lub zaproszenia',
        description: task.coldCallingName,
        redirectUrl: "#/cold-calling/" + task.coldCallingId
    });
}
