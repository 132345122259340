export function discountName(discount) {
    if (!discount) {
        return '';
    }
    switch (discount.discountGroup.id) {
        case 'PROFESSION_GROUP':
            return discount.discountGroup.name + " - " + discount.professionGroup.name;
        case 'DETAILED_SPECIAL_OFFER':
            return discount.discountGroup.name + " - " + discount.specialOfferType.name;
        case 'DETAILED_SPECIAL_OFFER_GMA':
            return discount.discountGroup.name + " - " + discount.specialOfferGlobalMajorAccount.name;
        case 'SECOND_GMA':
            return discount.discountGroup.name + " - " + discount.secondDiscountGroupGlobalMajorAccount.name;
        default:
            return "" + discount.discountGroup.name;
    }
}
