import { AbstractTask } from './abstractTask.component';
export function ActiveEmployeeSubstitutionTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Zmień ustawienia zastępstw',
        description: "Obecnie zg\u0142oszenia wracaj\u0105cych klient\u00F3w otrzymuje " + task.substitutionName,
        redirectUrl: '#/sales-substitution'
    });
}
