import React, { useState } from 'react';
import { withCancellation } from 'common/requests-cancellation/requestsCancellation';
import { useHistory } from 'react-router-dom';
import { useNewPartWholesaler } from './useNewPartWholesaler';
import Button from '../common/components/Button.component';
import InputMask from 'react-input-mask';
import UserContext from '../common/userContext';
import './newPartWholesaler.less';
function formatIdentificationNumber(nipNumber) {
    return nipNumber.replace(/ /g, '');
}
var regexp = /^[0-9]{10}$/;
export var NewPartWholesaler = withCancellation(function () {
    var history = useHistory();
    var newPartWholesaler = useNewPartWholesaler();
    var _a = useState(''), nipNumber = _a[0], setNipNumber = _a[1];
    var _b = useState(''), nipVerificationMsg = _b[0], setNipVerificationMsg = _b[1];
    var _c = useState(''), krsNumber = _c[0], setKrsNumber = _c[1];
    var _d = useState(''), krsVerificationMsg = _d[0], setKrsVerificationMsg = _d[1];
    var isServiceAssistant = UserContext.hasAnyRole('SERVICE_ASSISTANT');
    function nipInputHandler(e) {
        setNipVerificationMsg('');
        setNipNumber(e.currentTarget.value);
    }
    function krsInputHandler(e) {
        setKrsVerificationMsg('');
        setKrsNumber(e.currentTarget.value);
    }
    function blurNipHandler() {
        if (!regexp.test(formatIdentificationNumber(nipNumber)) && nipNumber !== '_ _ _ _ _ _ _ _ _ _') {
            setNipVerificationMsg('Numer NIP jest niepoprawny.');
        }
    }
    function blurKrsHandler() {
        if (!regexp.test(formatIdentificationNumber(krsNumber)) && krsNumber !== '_ _ _ _ _ _ _ _ _ _') {
            setKrsVerificationMsg('Numer KRS jest niepoprawny.');
        }
    }
    function verifyNipNumber(e) {
        e.preventDefault();
        if (!regexp.test(formatIdentificationNumber(nipNumber))) {
            setNipVerificationMsg('Numer NIP jest niepoprawny.');
            return;
        }
        setNipVerificationMsg('');
        newPartWholesaler
            .add(formatIdentificationNumber(nipNumber), 'NIP')
            .then(function (data) {
            history.push("/parts-wholesaler/" + data + "/" + UserContext.organizationId());
        })["catch"](function (error) { console.error(error); });
    }
    function verifyKrsNumber(e) {
        e.preventDefault();
        if (!regexp.test(formatIdentificationNumber(krsNumber))) {
            setKrsVerificationMsg('Numer KRS jest niepoprawny.');
            return;
        }
        setKrsVerificationMsg('');
        newPartWholesaler
            .add(formatIdentificationNumber(krsNumber), 'KRS')
            .then(function (data) {
            history.push("/parts-wholesaler/" + data + "/" + UserContext.organizationId());
        })["catch"](function (error) { console.error(error); });
    }
    function backToList() {
        history.push('/parts-wholesalers-list');
    }
    return (React.createElement("article", { className: "new-wholesaler" }, isServiceAssistant && (React.createElement(React.Fragment, null,
        React.createElement("h4", { className: "panel-title wholesaler-title" }, "Podmiot sprzeda\u017Cy hurtowej"),
        React.createElement("div", { className: "verification-container" },
            React.createElement("div", { className: "nip-number-verification-container" },
                React.createElement("form", { onSubmit: verifyNipNumber, className: "nip-number-verification-form" },
                    React.createElement("div", { className: "nip-number-row" },
                        React.createElement("label", { htmlFor: "nipNumber", className: "label-nip-number" }, "Numer NIP"),
                        React.createElement(InputMask, { mask: "9 9 9 9 9 9 9 9 9 9", id: "nipNumber", name: "nipNumber", className: "form-control input-nip-number " + (nipVerificationMsg ? 'invalid' : ''), value: nipNumber, onChange: nipInputHandler, required: true, onBlur: blurNipHandler }),
                        React.createElement(Button, { className: "btn btn-sm btn-confirm button-verify", "data-testid": 'verify-by-nip', type: 'submit' }, "Dodaj")),
                    React.createElement("div", { className: "invalidNIP", "data-testid": "invalid-nip-message" }, nipVerificationMsg))),
            React.createElement("div", { className: "krs-number-verification-container" },
                React.createElement("form", { onSubmit: verifyKrsNumber, className: "krs-number-verification-form" },
                    React.createElement("div", { className: "krs-number-row" },
                        React.createElement("label", { htmlFor: "krsNumber", className: "label-krs-number" }, "Numer KRS"),
                        React.createElement(InputMask, { mask: "9 9 9 9 9 9 9 9 9 9", id: "krsNumber", name: "krsNumber", className: "form-control input-krs-number " + (krsVerificationMsg ? 'invalid' : ''), value: krsNumber, onChange: krsInputHandler, required: true, onBlur: blurKrsHandler }),
                        React.createElement(Button, { className: "btn btn-sm btn-confirm button-verify", "data-testid": 'verify-by-krs', type: 'submit' }, "Dodaj")),
                    React.createElement("div", { className: "invalidKRS", "data-testid": "invalid-krs-message" }, krsVerificationMsg)))),
        React.createElement("div", { className: "action-buttons-container" },
            React.createElement(Button, { className: "btn btn-sm btn-default button-back", onClick: backToList }, "Wr\u00F3\u0107 do listy"))))));
});
