import { useHttp } from 'http/httpService';
export function useConcessions() {
    var http = useHttp();
    return {
        listActiveForIdentificationNumber: function (identificationNumber) {
            return http.get("/api/concessions?active&forIdentificationNumber=" + identificationNumber)
                .then(function (response) { return response.data; });
        },
        loadSettlementOfOrder: function (orderId) {
            return http.get("/api/concessions/settlements?ofOrder=" + orderId)
                .then(function (response) { return response.data; });
        }
    };
}
