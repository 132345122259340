import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withCancellation } from 'common/requests-cancellation/requestsCancellation';
import { DealerSearch } from './DealerSearch.component';
import { PaginatedItems } from './PaginatedItems.component';
import { HeaderList } from './HeaderList.component';
import { usePartsWholesaleWhitelistedParties } from './usePartsWholesaleWhitelistedParties';
import Button from '../common/components/Button.component';
import './partsWholesaleWhitelistedParties.less';
import UserContext from 'common/userContext';
import localStorage from 'common/localStorage';
import { Tab, Tabs } from 'react-bootstrap';
import { WholesalerInputSearch } from './WholesalerInputSearch.component';
import { MessageBox } from 'common/message-box/messageBox';
export var PartsWholesaleWhitelistedParties = withCancellation(function () {
    var _a = useState([]), dealers = _a[0], setDealers = _a[1];
    var _b = useState(undefined), selectedDealer = _b[0], setSelectedDealer = _b[1];
    var _c = useState(undefined), parties = _c[0], setParties = _c[1];
    var _d = useState(undefined), allParties = _d[0], setAllParties = _d[1];
    var _e = useState(''), phrase = _e[0], setPhrase = _e[1];
    var _f = useState('dealer-search'), activeTabKey = _f[0], setActiveTabKey = _f[1];
    var _g = useState(''), globalSuspendingPartyPending = _g[0], setGlobalSuspendingPartyPending = _g[1];
    var history = useHistory();
    var partsWholesaleWhitelistedParties = usePartsWholesaleWhitelistedParties();
    var isCustomerServiceVCP = UserContext.hasAnyRole('VCP_CUSTOMER_SERVICE');
    var isServiceAssistant = UserContext.hasAnyRole('SERVICE_ASSISTANT');
    var showPartiesForDealerHandler = function () {
        partsWholesaleWhitelistedParties
            .getParties(selectedDealer.organizationId)
            .then(function (data) {
            setParties(Object.values(data.parties));
        });
    };
    var addNewPartyHandler = function () {
        history.push('/parts-wholesaler/new');
    };
    useEffect(function () {
        if (isCustomerServiceVCP) {
            partsWholesaleWhitelistedParties.getDealers().then(function (data) {
                var dealers = data.map(function (dealer) {
                    return {
                        value: dealer.organizationName,
                        label: dealer.organizationName,
                        organizationId: dealer.organizationId,
                    };
                });
                setDealers(dealers.sort(function (dealerA, dealerB) {
                    var dealerNameA = dealerA.label.toUpperCase();
                    var dealerNameB = dealerB.label.toUpperCase();
                    if (dealerNameA < dealerNameB) {
                        return -1;
                    }
                    if (dealerNameA > dealerNameB) {
                        return 1;
                    }
                    return 0;
                }));
                if (localStorage.get('lastSelectedOrganizationId')) {
                    setSelectedDealer(dealers.find(function (dealer) {
                        return dealer.organizationId ===
                            localStorage.get('lastSelectedOrganizationId');
                    }));
                    partsWholesaleWhitelistedParties
                        .getParties(localStorage.get('lastSelectedOrganizationId'))
                        .then(function (data) {
                        setParties(Object.values(data.parties));
                    });
                    localStorage.remove('lastSelectedOrganizationId');
                }
                partsWholesaleWhitelistedParties.getAllParties().then(function (data) {
                    var allParties = Object.entries(data).map(function (entry) {
                        return {
                            nip: entry[0],
                            name: entry[1][0].name,
                            suspendedGlobally: entry[1][0].suspendedGlobally,
                            dealers: entry[1].map(function (dealer) { return ({
                                name: dealer.organizationFullName,
                                organizationId: dealer.organizationId,
                                suspended: dealer.suspended,
                                startOfActivity: dealer.startOfActivity,
                            }); }),
                        };
                    });
                    setAllParties(allParties);
                });
            });
        }
        if (isServiceAssistant) {
            partsWholesaleWhitelistedParties
                .getParties(UserContext.organizationId())
                .then(function (data) {
                setParties(Object.values(data.parties));
            });
        }
    }, []);
    function showWholesalerHandler(nip) {
        history.push("/parts-wholesaler/" + nip + "/" + (isCustomerServiceVCP ? selectedDealer.organizationId : UserContext.organizationId()));
    }
    function suspensionCheckboxHandler(checked, nip) {
        partsWholesaleWhitelistedParties
            .setLocalSuspension(nip, checked, selectedDealer.organizationId)
            .then(function () {
            partsWholesaleWhitelistedParties
                .getParties(selectedDealer.organizationId)
                .then(function (data) {
                setParties(Object.values(data.parties));
            });
            partsWholesaleWhitelistedParties.getAllParties().then(function (data) {
                var allParties = Object.entries(data).map(function (entry) {
                    return {
                        nip: entry[0],
                        name: entry[1][0].name,
                        suspendedGlobally: entry[1][0].suspendedGlobally,
                        dealers: entry[1].map(function (dealer) { return ({
                            name: dealer.organizationFullName,
                            organizationId: dealer.organizationId,
                            suspended: dealer.suspended,
                            startOfActivity: dealer.startOfActivity,
                        }); }),
                    };
                });
                setAllParties(allParties);
            });
        });
    }
    function globalSuspensionCheckboxHandler(checked, nip) {
        MessageBox.confirmation("Czy na pewno " + (checked ? 'zawiesić' : 'odwiesić') + " podmiot wszystkim dealerom?")
            .then(function () {
            setGlobalSuspendingPartyPending(nip);
            partsWholesaleWhitelistedParties
                .setGlobalSuspension(nip, checked)
                .then(function () {
                partsWholesaleWhitelistedParties.getAllParties().then(function (data) {
                    var allParties = Object.entries(data).map(function (entry) {
                        return {
                            nip: entry[0],
                            name: entry[1][0].name,
                            suspendedGlobally: entry[1][0].suspendedGlobally,
                            dealers: entry[1].map(function (dealer) { return ({
                                name: dealer.organizationFullName,
                                organizationId: dealer.organizationId,
                                suspended: dealer.suspended,
                                startOfActivity: dealer.startOfActivity,
                            }); }),
                        };
                    });
                    setAllParties(allParties);
                    setGlobalSuspendingPartyPending('');
                });
                {
                    selectedDealer ?
                        partsWholesaleWhitelistedParties
                            .getParties(selectedDealer.organizationId)
                            .then(function (data) {
                            setParties(Object.values(data.parties));
                        }) : null;
                }
            });
        })["catch"](function () { });
    }
    function applyPhraseFilter(phrase) {
        setPhrase(phrase);
    }
    var visibleWholeselers = (activeTabKey === 'dealer-search' && parties) || isServiceAssistant
        ? phrase === ''
            ? parties
            : parties.filter(function (party) {
                return party.nip.toLowerCase().includes(phrase.toLowerCase()) ||
                    party.name.toLowerCase().includes(phrase.toLowerCase());
            })
        : [];
    var visibleAllWholeselers = activeTabKey === 'global-suspend'
        ? phrase === ''
            ? allParties
            : allParties.filter(function (party) {
                return party.nip.toLowerCase().includes(phrase.toLowerCase()) ||
                    party.name.toLowerCase().includes(phrase.toLowerCase());
            })
        : [];
    return (React.createElement("article", { className: "parts-wholesalers-list" },
        React.createElement("h4", { className: "panel-title wholesaler-title" }, "Podmioty dopuszczone do hurtowej sprzeda\u017Cy cz\u0119\u015Bci"),
        isCustomerServiceVCP ? (React.createElement(Tabs, { className: "tabs-container", id: "wholesale-whitelist-nav", activeKey: activeTabKey, onSelect: function (activeKey) { return setActiveTabKey(activeKey); }, mountOnEnter: true, unmountOnExit: true },
            React.createElement(Tab, { className: "dealerlist__tab", eventKey: "dealer-search", title: "Podmioty sprzeda\u017Cy hurtowej dealera" },
                React.createElement("section", { className: "dealer-search__section", "data-testid": "dealer-search__section" },
                    React.createElement("div", { "data-testid": "dealer-search", className: "dealer-search" },
                        React.createElement(DealerSearch, { dealers: dealers, onChange: function (dealer) { return setSelectedDealer(dealer); }, onClick: showPartiesForDealerHandler, selectedDealer: selectedDealer }))),
                parties ? (React.createElement("div", null,
                    React.createElement(WholesalerInputSearch, { phrase: phrase, onApplyPhraseFilter: applyPhraseFilter }),
                    React.createElement("section", { className: "list" },
                        React.createElement(HeaderList, { isGlobalSuspend: false }),
                        React.createElement(PaginatedItems, { itemsPerPage: 10, items: visibleWholeselers, onItemClick: showWholesalerHandler, onChangeStatus: suspensionCheckboxHandler, isGlobalSuspend: false, key: phrase })))) : null),
            React.createElement(Tab, { className: "__tab", eventKey: "global-suspend", title: "Podmioty sprzeda\u017Cy hurtowej sieci" },
                React.createElement("section", { className: "global-suspend__section" }, allParties ? (React.createElement("div", null,
                    React.createElement(WholesalerInputSearch, { phrase: phrase, onApplyPhraseFilter: applyPhraseFilter }),
                    visibleAllWholeselers ?
                        React.createElement("section", { className: "list" },
                            React.createElement(HeaderList, { isGlobalSuspend: true }),
                            React.createElement(PaginatedItems, { itemsPerPage: 30, items: visibleAllWholeselers, onItemClick: showWholesalerHandler, onChangeStatus: globalSuspensionCheckboxHandler, isGlobalSuspend: true, key: phrase, globalSuspendingPartyPending: globalSuspendingPartyPending }))
                        : null)) : null)))) : null,
        isServiceAssistant ? (React.createElement("div", null,
            React.createElement(WholesalerInputSearch, { phrase: phrase, onApplyPhraseFilter: applyPhraseFilter }),
            React.createElement(Button, { className: "btn btn-sm btn-confirm button-add", onClick: addNewPartyHandler }, "Dodaj nowy podmiot"),
            visibleWholeselers ? (React.createElement("section", { className: "list", "data-testid": "parts-wholesale-list" },
                React.createElement(HeaderList, { isGlobalSuspend: false }),
                React.createElement(PaginatedItems, { itemsPerPage: 10, items: visibleWholeselers, onItemClick: showWholesalerHandler, isGlobalSuspend: false, key: phrase }))) : null)) : null));
});
