import { AbstractTask } from './abstractTask.component';
export function ClientsWithNotActiveAssistantTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Przenieś opiekę',
        description: task.count + " klient\u00F3w znajduje si\u0119 pod opiek\u0105 by\u0142ych sprzedawc\u00F3w.",
        redirectUrl: '#/change-assistant?assignmentScope=CLIENTS_WITH_NOT_ACTIVE_ASSISTANT'
    });
}
