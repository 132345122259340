import { useHttp } from 'http/httpService';
export function useTimeline() {
    var http = useHttp();
    return {
        loadForCurrentAssistant: function (from, to) {
            return http.get("/api/timeline?ofCurrentAssistant&from=" + from + "&to=" + to);
        },
        loadForAssistant: function (assistantId, from, to) {
            return http.get("/api/timeline?ofAssistant=" + assistantId + "&from=" + from + "&to=" + to);
        }
    };
}
