import ModelDictionary from './modelDictionary';
import _ from 'lodash';
export var modelName = function (modelId, short) {
    if (short === void 0) { short = false; }
    var modelYear = short ? '' : " (" + modelYearOf(modelId) + ")";
    return modelId ? "" + modelNameOf(modelId) + modelYear : '';
    function modelNameOf(modelId) {
        var modelCode = modelId.split('_')[0];
        return ModelDictionary.nameFor(modelCode);
    }
    function modelYearOf(modelId) {
        return modelId ? modelId.split('_')[1] : '';
    }
};
export var shortModelName = function (modelId) {
    return modelName(modelId, true);
};
export function abbreviateModelName(modelName) {
    return _.replace(modelName, 'Cross Country', 'CC');
}
