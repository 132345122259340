import { AbstractTask } from './abstractTask.component';
export function ConcessionApplicationSupplementsToAcceptTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Rozszerz rabat VCP',
        description: task.dealer + " " + task.client,
        redirectUrl: "#/concessions/supplement-acceptance/" + task.concessionApplicationId + "/" + task.supplementId
    });
}
