import { AbstractTask } from './abstractTask.component';
export function ExpectedSeasonalMaintenancesToConfirmTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Umów',
        description: "Nadchodz\u0105ce przegl\u0105dy: " + task.count,
        redirectUrl: '#/service-call-center'
    });
}
