import React from 'react';
import UserContext from 'common/userContext';
export var HeaderList = function (_a) {
    var isGlobalSuspend = _a.isGlobalSuspend;
    var isCustomerServiceVCP = UserContext.hasAnyRole('VCP_CUSTOMER_SERVICE');
    return (React.createElement("div", { className: 'header-list' },
        React.createElement("table", { className: "table table-header wholesalers-table" },
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: "table-nip-col " + (isCustomerServiceVCP ? 'table-nip-col-vcp' : '') }, "Nr NIP"),
                    React.createElement("th", { className: "table-name-col " + (isCustomerServiceVCP ? 'table-name-col-vcp' : '') }, "Nazwa firmy"),
                    React.createElement("th", { className: "table-status-col " + (isCustomerServiceVCP ? 'table-status-col-vcp' : '') }, "Status"),
                    React.createElement("th", { className: "table-activity-from-col " + (isCustomerServiceVCP ? 'table-activity-from-col-vcp' : '') + " " + (isGlobalSuspend ? 'global-suspend-table-activity-from-col' : '') }, "Pocz\u0105tek aktywno\u015Bci")))),
        isCustomerServiceVCP ?
            React.createElement("div", { className: "table-suspension" }, isGlobalSuspend ? 'Zawieszenie globalne' : 'Zawieszenie')
            : null));
};
