import { AbstractTask } from './abstractTask.component';
export function ConcessionApplicationsSettlementCorrectionApplicationsToAcceptTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Skoryguj rozliczenie',
        description: task.dealer + " " + task.dealerOrder,
        redirectUrl: "#/concessions/settlement-correction-application-acceptance/" + task.concessionApplicationId + "/" + task.orderId
    });
}
