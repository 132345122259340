import { AbstractTask } from './abstractTask.component';
export function CorrectingRegistrationApplicationsTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Skoryguj rejestrację',
        description: task.dealerOrder + " " + task.dealerName,
        redirectUrl: "#/sale-cars/" + task.commonOrderNumber
    });
}
