import { shortPartyDescription } from 'common/party';
import { withCancellation } from 'common/requests-cancellation/requestsCancellation';
import { ActivePartyVcpDiscounts } from 'extra-sale-discounts/activePartyVcpDiscounts';
import { ApplicationData } from 'extra-sale-discounts/applicationData';
import { CarSalePotential } from 'extra-sale-discounts/carSalePotential';
import { PartyData } from 'extra-sale-discounts/partyData';
import { PartyExtraSaleDiscounts } from 'extra-sale-discounts/partyExtraSaleDiscounts';
import { usePreview } from 'extra-sale-discounts/preview/usePreview';
import { UserCard } from 'extra-sale-discounts/UserCard';
import { VcpDiscountSettlement } from 'extra-sale-discounts/vcpDiscountSettlement';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ViewSection } from '../ViewSection';
export var Preview = withCancellation(function () {
    var _a = usePreview(useParams().applicationId), applicant = _a.applicant, application = _a.application, carGrossListPrice = _a.carGrossListPrice, partyActiveConcessions = _a.partyActiveConcessions, partyCars = _a.partyCars, partyExtraSaleDiscounts = _a.partyExtraSaleDiscounts, settlement = _a.settlement;
    return React.createElement("article", { className: "extra-sale-discount-preview", style: { marginBottom: '20px' } },
        React.createElement(ViewSection, { title: "Wnioskuj\u0105cy", "data-testid": "applicantSection" },
            React.createElement(UserCard, { user: applicant })),
        React.createElement(ViewSection, { title: "Zamawiaj\u0105cy", "data-testid": "partySection" },
            React.createElement(PartyData, { application: application, partyCars: partyCars })),
        React.createElement(ViewSection, { title: "Potencja\u0142 sprzeda\u017Cowy", "data-testid": "carsSalePotentialSection" },
            React.createElement(CarSalePotential, { application: application })),
        React.createElement(ViewSection, { title: "Rabat DRS", "data-testid": "applicationSection" },
            React.createElement(ApplicationData, { application: application, carGrossListPrice: carGrossListPrice, editableApplicationReasons: function () { return false; }, showChangesHistory: true, showDiscountTypes: false })),
        React.createElement(ViewSection, { title: 'Aktywne rabaty VCP dla strony: ' + shortPartyDescription(application === null || application === void 0 ? void 0 : application.party), "data-testid": "importerDiscountsSection" },
            React.createElement(ActivePartyVcpDiscounts, { partyActiveConcessions: partyActiveConcessions })),
        React.createElement(ViewSection, { title: "Rozliczenie rabatu VCP samochodu: CON " + (application === null || application === void 0 ? void 0 : application.car.commonOrderNumber), "data-testid": "settlement-section" },
            React.createElement(VcpDiscountSettlement, { settlement: settlement })),
        React.createElement(ViewSection, { title: "Rabaty DRS dla strony: " + shortPartyDescription(application === null || application === void 0 ? void 0 : application.party), "data-testid": "extra-sale-discounts-for-party-section" },
            React.createElement(PartyExtraSaleDiscounts, { partyExtraSaleDiscounts: partyExtraSaleDiscounts })));
});
