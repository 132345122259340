import * as _ from 'lodash';
import { identificationNumberDescription } from './formatters/identificationNumber';
import { partyType } from './formatters/partyType';
export var partyDescription = function (party) {
    if (!party) {
        return '';
    }
    return _.chain([partyType(party.type), party.name, identificationNumberDescription(party.identificationNumber)])
        .filter(function ($) { return !!$; })
        .join(' ')
        .value();
};
export var shortPartyDescription = function (party) {
    if (!party) {
        return '';
    }
    var identificationNumber = identificationNumberDescription(party.identificationNumber);
    return _.chain([party.name, identificationNumber ? "(" + identificationNumber + ")" : ''])
        .filter(function ($) { return !!$; })
        .join(' ')
        .value();
};
