var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Select, { components } from 'react-select';
import Button from '../common/components/Button.component';
export function DealerSearch(_a) {
    var dealers = _a.dealers, onChange = _a.onChange, onClick = _a.onClick, selectedDealer = _a.selectedDealer;
    var Option = function (props) {
        return (React.createElement(components.Option, __assign({}, props),
            React.createElement("div", { "data-testid": "option-" + props.selectProps.getOptionLabel(props.data), className: "option" },
                "\u00A0",
                props.children)));
    };
    var selectCustomStyles = {
        option: function (provided, state) { return (__assign(__assign({}, provided), { backgroundColor: state.isFocused ? '#f5f5f5' : undefined, color: '#333' })); },
        control: function (base) { return (__assign(__assign({}, base), { borderRadius: 0, boxShadow: 'none', minHeight: '33px', height: '33px', '&:hover': {
                borderColor: 'A',
            }, borderColor: '#ccc' })); },
        valueContainer: function (provided) { return (__assign(__assign({}, provided), { height: '33px' })); },
        indicatorsContainer: function (provided) { return (__assign(__assign({}, provided), { height: '33px' })); },
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "dealer-select", "data-testid": "dealer-select-component" },
            React.createElement("label", { className: "dealer-select-label" }, "Dealer"),
            React.createElement(Select, { options: dealers, placeholder: "", noOptionsMessage: function () { return ''; }, style: { flexGrow: 1 }, className: "dealer-search-select-container", onChange: onChange, styles: selectCustomStyles, value: selectedDealer, "aria-label": "dealer", components: { Option: Option } })),
        React.createElement(Button, { className: "btn btn-sm btn-confirm button-show-dealer", onClick: onClick, disabled: !selectedDealer }, "Poka\u017C")));
}
