import { AbstractTask } from './abstractTask.component';
import { phoneNumber } from '../../../common/phoneNumber';
export function ConsentWithdrawalApplicationsTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Wycofaj zgody',
        description: task.contactDetails ? [phoneNumber(task.contactDetails.phoneNumber), task.contactDetails.email].filter(function ($) { return !!$; }).join(' ') : '',
        redirectUrl: "#/consent-withdrawal-confirmation?id=" + task.id
    });
}
