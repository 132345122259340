var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect, useState } from 'react';
import { withCancellation } from 'common/requests-cancellation/requestsCancellation';
import { useHistory, useParams } from 'react-router-dom';
import { usePartWholesaler } from './usePartWholesaler';
import { ActivityPeriodForm } from './ActivityPeriodForm.component';
import { PeriodsList } from './PeriodsList.component';
import Button from 'common/components/Button.component';
import UserContext from 'common/userContext';
import localStorage from 'common/localStorage';
import moment from 'moment';
import './partWholesaler.less';
function convertDateStringToTime(date) {
    return new Date(date).getTime();
}
export var PartWholesaler = withCancellation(function () {
    var _a = useParams(), nip = _a.nip, organizationId = _a.organizationId;
    var partWholesaler = usePartWholesaler();
    var history = useHistory();
    var _b = useState(undefined), wholesalerData = _b[0], setWholesalerData = _b[1];
    var _c = useState(dateNow()), startActivityPeriod = _c[0], setStartActivityPeriod = _c[1];
    var _d = useState(dateNow()), endActivityPeriod = _d[0], setEndActivityPeriod = _d[1];
    var _e = useState(false), endOfPeriodDefined = _e[0], setEndOfPeriodDefined = _e[1];
    var _f = useState(null), editedPeriodId = _f[0], setEditedPeriodId = _f[1];
    var _g = useState(startActivityPeriod), minimumToDate = _g[0], setMinimumToDate = _g[1];
    var _h = useState([]), draftPeriods = _h[0], setDraftPeriods = _h[1];
    var isCustomerServiceVCP = UserContext.hasAnyRole('VCP_CUSTOMER_SERVICE');
    useEffect(function () {
        partWholesaler
            .getParty(nip, isCustomerServiceVCP ? organizationId : UserContext.organizationId())
            .then(function (data) {
            setWholesalerData(data);
            setDraftPeriods(data.activityPeriods.map(function (period) { return (__assign({}, period)); }));
            var newStartActivityPeriod = moment(data.minimumFromDateNewActivityPeriod)
                .isAfter(dateNow()) ? moment(data.minimumFromDateNewActivityPeriod).toISOString() : dateNow();
            setStartActivityPeriod(newStartActivityPeriod);
        });
    }, []);
    function backToList() {
        localStorage.set('lastSelectedOrganizationId', organizationId);
        history.push('/parts-wholesalers-list');
    }
    function saveChanges(e) {
        e.preventDefault();
        var dataToSend = draftPeriods.map(function (period) {
            return {
                id: period.id,
                from: period.from,
                to: period.to,
                endOfPeriodDefined: period.endOfPeriodDefined,
            };
        });
        if (editedPeriodId === null && !isCustomerServiceVCP) {
            dataToSend.push({
                id: null,
                from: convertDateStringToTime(startActivityPeriod),
                to: endOfPeriodDefined ? convertDateStringToTime(endActivityPeriod) : null,
                endOfPeriodDefined: endOfPeriodDefined,
            });
        }
        partWholesaler
            .updatePeriods(wholesalerData.nip, isCustomerServiceVCP ? organizationId : UserContext.organizationId(), dataToSend)
            .then(function () { return history.go(0); });
    }
    function dateNow() {
        return moment().toISOString();
    }
    function applyStartDate(newDate) {
        setStartActivityPeriod(newDate);
        if (moment(newDate).isAfter(endActivityPeriod)) {
            setEndActivityPeriod(newDate);
            setMinimumToDate(newDate);
        }
        if (moment(endActivityPeriod).isAfter(newDate)) {
            setMinimumToDate(newDate);
        }
    }
    function applyEndDate(newDate) {
        setEndActivityPeriod(newDate);
    }
    function toggleEndOfActivityPeriodDefined() {
        setEndOfPeriodDefined(function (prevState) { return !prevState; });
    }
    function editPeriod(periodId) {
        setEditedPeriodId(periodId);
    }
    function applyStartDateEditedPeriod(index, newDate) {
        var editedPeriodsAfterDateChange = __spreadArrays(draftPeriods);
        editedPeriodsAfterDateChange[index].from = convertDateStringToTime(newDate);
        if (moment(newDate).isAfter(editedPeriodsAfterDateChange[index].to)) {
            editedPeriodsAfterDateChange[index].to = convertDateStringToTime(newDate);
        }
        setDraftPeriods(editedPeriodsAfterDateChange);
    }
    function applyEndDateEditedPeriod(index, newDate) {
        var editedPeriodsAfterDateChange = __spreadArrays(draftPeriods);
        editedPeriodsAfterDateChange[index].to = convertDateStringToTime(newDate);
        editedPeriodsAfterDateChange[index].endOfPeriodDefined = true;
        setDraftPeriods(editedPeriodsAfterDateChange);
    }
    function checkAllPeriodsAreClosed() {
        return wholesalerData.activityPeriods
            .map(function (period) { return period.endOfPeriodDefined; })
            .every(function (endOfPeriodDefined) { return endOfPeriodDefined; });
    }
    return (React.createElement("article", { className: "wholesaler-preview" },
        React.createElement("h4", { className: "panel-title wholesaler-title" }, "Podmiot sprzeda\u017Cy hurtowej"),
        wholesalerData ? (React.createElement("div", { className: "new-wholesaler-preview" },
            React.createElement("div", { className: "wholesaler-data", "data-testid": "wholesaler-data" },
                React.createElement("label", { className: "wholesaler-nip-label" }, "Numer NIP"),
                React.createElement("p", { "data-testid": "wholesaler-nip" }, wholesalerData.nip),
                React.createElement("p", { "data-testid": "wholesaler-name", className: "wholesaler-name" }, wholesalerData.name)),
            React.createElement("div", { className: "activity-periods", "data-testid": "wholesaler-activity-periods" },
                React.createElement("label", null, "Okresy aktywno\u015Bci"),
                React.createElement(PeriodsList, { periods: draftPeriods, editPeriod: editPeriod, editedPeriodId: editedPeriodId, applyStartDate: applyStartDateEditedPeriod, applyEndDate: applyEndDateEditedPeriod, isSuspended: wholesalerData.suspended }),
                !checkAllPeriodsAreClosed() && editedPeriodId === null && !isCustomerServiceVCP && !wholesalerData.suspended ? (React.createElement("p", { className: 'new-period-message' }, "Uwaga: aby doda\u0107 nowy okres aktywno\u015Bci zdefiniuj dat\u0119 ko\u0144ca ostatniego okresu na li\u015Bcie.")) : null,
                editedPeriodId === null && !isCustomerServiceVCP && checkAllPeriodsAreClosed() && !wholesalerData.suspended ? (React.createElement(ActivityPeriodForm, { startActivityPeriod: startActivityPeriod, applyStartDate: applyStartDate, endActivityPeriod: endActivityPeriod, applyEndDate: applyEndDate, minimumToDate: minimumToDate, endOfPeriodDefined: endOfPeriodDefined, toggleEndOfActivityPeriodDefined: toggleEndOfActivityPeriodDefined, minimumFromDateNewActivityPeriod: wholesalerData.minimumFromDateNewActivityPeriod })) : null))) : null,
        wholesalerData ? (React.createElement("div", { className: "action-buttons-container", "data-testid": "action-buttons-container" },
            React.createElement(Button, { className: "btn btn-sm btn-default button-back", onClick: backToList }, "Wr\u00F3\u0107 do listy"),
            React.createElement(Button, { className: "btn btn-sm btn-confirm button-save", onClick: saveChanges, form: "activity-periods", disabled: wholesalerData.suspended || (!checkAllPeriodsAreClosed() && editedPeriodId === null) }, "Zapisz"))) : null));
});
