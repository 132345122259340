import { useHttp } from 'http/httpService';
export function useCars() {
    var http = useHttp();
    return {
        byPartyIdentificationNumber: function (identificationNumber, from, to) {
            return http.get("/api/cars?byPartyIdentificationNumber=" + identificationNumber + "&from=" + from + "&to=" + to)
                .then(function (response) { return response.data; });
        }
    };
}
