import { AbstractTask } from './abstractTask.component';
export function MissingEurocodesTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Uzupełnij brakujące Eurokody',
        description: "Brakuj\u0105ce: " + task.count,
        redirectUrl: '#/eurocodes-import'
    });
}
