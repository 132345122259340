import { polishTimestamp } from 'common/formatters/date';
import { modelName } from 'common/models';
import { AbstractTask } from './abstractTask.component';
export function OrderWithdrawalApplicationsToAcceptWithoutSettlementTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Wycofaj zamówienie',
        description: [task.applicationSubmissionTimestamp ? "Wniosek z " + polishTimestamp(task.applicationSubmissionTimestamp) : undefined, task.dealerName, modelName(task.modelId), task.dealerOrder].filter(function ($) { return !!$; }).join(', '),
        redirectUrl: "#/order-withdrawal-without-settlement/" + task.orderId
    });
}
