import { AbstractTask } from './abstractTask.component';
export function PendingOwnPhoneNumberCampaignTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Wykonaj',
        description: "Kampania telefoniczna: " + task.campaignName,
        redirectUrl: "#/own-phone-number-campaign/" + task.id
    });
}
